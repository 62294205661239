<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";

import { ref, watch, onBeforeMount, onMounted } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import c3api from "../../c3api";
import { useToast } from "vue-toastification";
import { LicenseManager } from "ag-grid-enterprise";
import { useRouter } from "vue-router";
import BillingClientFilter from "../../components/ag-filters/BillingClientFilter.vue";
import BillingOrderActivitiesPopup from "../../components/billings/BillingOrderActivitiesPopUp.vue";
import { useThemeStore } from "@/stores/themeStore";
import pageTitle from "../../utils/pageTitle";

import {
  formatCurrency,
  workflowStateValueFormatter,
  clientValueFormatter,
} from "@/utils/dataUtils";
import {
  saveGridState,
  loadGridState,
  addPrefixToId,
  resetGridColState,
  resetGridFilterState,
} from "@/utils/agGridUtils";
import OrderBillDetailRenderer from "@/components/OrderBillDetailRenderer.vue";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const loadCount = ref(0);
const selectedInvoice = ref("");
const invoicesSelect = ref([]);
const router = useRouter();
const disableInvoicing = ref(true);
const toggleInvoiceRadio = ref(false);
const disableInvoiceSubmit = ref(true);
const showAppendInvoice = ref(false);
const showFilters = ref(false);
const detailCellRenderer = ref(null);
const slosProcessDate = ref(new Date(Date.now()));
const CREATE_INVOICE_RADIO = "create_invoice";
const APPEND_INVOICE_RADIO = "append_invoice";
const GRID_SAVE_KEY = "billingOrders";

let context = ref(null);
let gridApi;
const toast = useToast();
let gridParams = "";
let dialog = ref(false);

const onGridReady = (params) => {
  gridParams = params;
  gridApi = params.api;
  addFuncsToColDefs();
  loadGridState(GRID_SAVE_KEY, gridApi, colDefs.value);
  context.value = {
    onBillSaveCallback,
  };
};
const onFirstDataRendered = (params) => {
  loadGridState(GRID_SAVE_KEY, params.api, colDefs.value);
};

const onBillSaveCallback = () => {
  gridApi.refreshServerSide();
};

const defaultColDef = ref({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  suppressMenu: true,
  enableCellChangeFlash: true,
  resizable: true,
  width: 120,
  serverSideSortingAlwaysResets: false,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

const rangeFilterParams = {
  filterOptions: [
    "equals",
    "greaterThanOrEqual",
    "lessThanOrEqual",
    "inRange",
    "blank",
    "contains",
  ],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};
const dateFilterParams = {
  filterOptions: ["equals", "greaterThanOrEqual", "lessThanOrEqual", "inRange", "blank"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};
const textFilterParams = {
  filterOptions: ["equals", "blank", "contains", "notEqual"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};

const valueGetters = {
  created_at: (params) => params.data?.created_at?.substring(0, 10),
  billed_at: (params) => params.data?.billed_at?.substring(0, 10),
  id: (params) => {
    return addPrefixToId("B", params.data?.id);
  },
  order_invoice_id: (params) => {
    return addPrefixToId("I", params.data?.order_invoice_id);
  },
};

const advancedFilterKeys = [
  "id",
  "created_at",
  "billed_at",
  "client.name",
  "order_number",
  "order_type.name",
  "po_number",
  "amount",
  "status_id",
  "order_status.name",
  "order_invoice.workflow_state",
  "order_invoice_id",
];

const buildQueryParams = function (params, csv = false) {
  const urlParams = {
    sortModel:
      params.request.sortModel.length > 0
        ? params.request.sortModel
        : [{ colId: "id", sort: "desc" }],
    filterModel: params.request.filterModel,
    startRow: params.request.startRow,
    endRow: params.request.endRow,
    batch_size: 100,
    limit: 100,
  };

  const filterParams = {};
  if (params.request.filterModel) {
    for (let [k, v] of Object.entries(params.request.filterModel)) {
      if (advancedFilterKeys.includes(k)) {
        if (v.filterType === "set") {
          console.log(v);
          if (k == "status_id") {
            filterParams[k] = {
              filter: Object.keys(v.values),
              type: v.filterType,
            };
          } else {
            filterParams[k] = {
              filter: v.values,
              type: v.filterType,
            };
          }
        } else {
          filterParams[k] = v;
        }
      } else {
        urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
      }
    }
  }

  urlParams.filter = filterParams;
  if (typeof params.request.startRow !== "undefined") {
    urlParams.start_row = params.request.startRow;
    urlParams.limit = params.request.endRow - params.request.startRow;
  }
  if (csv === true) {
    urlParams.format = "csv";
  }

  return urlParams;
};

const connectDatasource = {
  getRows: async (params) => {
    loadCount.value++;
    console.log("getRows called with sortModel:", params.request.sortModel);
    try {
      const urlParams = buildQueryParams(params);
      console.log("Built Query Params:", urlParams);
      gridApi.showLoadingOverlay();
      const response = await c3api.get("/order_bills", { params: urlParams });
      console.log("Response Data:", response.data);
      const data = response.data;

      // Using params.success to pass the data to the grid
      params.success({
        rowData: data.data,
        rowCount: data.total_count,
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.data.join(". "));
      params.fail();
    } finally {
      loadCount.value--;
      if (loadCount.value <= 0) {
        gridApi.hideOverlay();
      }
    }
  },
  maxConcurrentDatasourceRequests: 1,
};

const makeGetApiCall = async (url, params) => {
  const response = await c3api.get(url, params);
  return response;
};

const makePostApiCall = async (url, params) => {
  const response = await c3api.post(url, params);
  return response;
};

const makePatchApiCall = async (url, params) => {
  const response = await c3api.patch(url, params);
  return response;
};

//6,9,11,15,16,17

const constructSelectComponentItems = async () => {
  const selectedClient = gridApi.getSelectedRows()[0]?.client_id;
  const config = {
    params: {
      filter: {
        ["client.id"]: {
          type: "equals",
          filter: selectedClient,
          filterType: "text",
        },
        ["workflow_state"]: {
          type: "equals",
          filter: "draft",
          filterType: "text",
        },
      },
      limit: 100,
    },
  };

  let invoices = await makeGetApiCall("/order_invoices", config);
  let invoicesList = invoices?.data?.data;
  invoicesSelect.value.length = 0;
  invoicesList.forEach((invoice) => {
    let invoiceObj = { text: `Invoice-${invoice.id}`, value: invoice.id };
    invoicesSelect.value.push(invoiceObj);
  });
};

const statusMapping = {
  created: 0,
  pending: 1,
  billed: 2,
  for_review: 3,
  unknown: 4,
};

const statusLabels = {
  0: "New",
  1: "Pending",
  2: "Billed",
  3: "For Review",
  4: "Unknown Status",
};

const colDefs = ref([
  {
    field: "id",
    headerName: "Bill #",
    cellDataType: "text",
    width: 200,
    checkboxSelection: true,
    cellRenderer: "agGroupCellRenderer",
  },
  {
    field: "billable_amount",
    colIf: "billable_amount",
    headerName: "Amount",
    cellDataType: "number",
    width: 130,
    sortable: true,
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: "status_id",
    colId: "status_id",
    headerName: "Bill Status",
    cellDataType: "text",
    width: 150,
    filter: "agSetColumnFilter",
    filterParams: {
      values: Object.values(statusLabels),
      valueFormatter: (params) => {
        return statusLabels[params.value];
      },
    },
    valueGetter: (params) => {
      const statusId = params.data?.status_id;
      return statusMapping[statusId] !== undefined ? statusMapping[statusId] : 4;
    },
    valueFormatter: (params) => {
      return statusLabels[params.value];
    },
  },
  {
    field: "order_number",
    headerName: "Order #",
    cellDataType: "text",
    width: 135,
    filterParams: rangeFilterParams,
  },
  {
    field: "created_at",
    sort: "desc",
    colId: "created_at",
    headerName: "Billable On",
    cellDataType: "dateString",
    width: 160,
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
  },

  {
    field: "client.name",
    colId: "client.name",
    headerName: "Client",
    cellDataType: "text",
    width: 200,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
          },
        },
        {
          filter: BillingClientFilter,
          filterParams: {
            valueGetter: (params) => params.data.client.name,
          },
          valueFormatter: clientValueFormatter,
        },
      ],
    },
  },
  { field: "po_number", headerName: "PO #", cellDataType: "text", width: 170 },

  {
    field: "billed_at",
    sort: "desc",
    colId: "billed_at",
    headerName: "Billed On",
    cellDataType: "dateString",
    width: 170,
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
  },
  {
    field: "order_invoice_id",
    headerName: "Invoice Number",
    cellDataType: "text",
    width: 150,
  },
  {
    field: "order_invoice.workflow_state",
    colId: "order_invoice.workflow_state",
    headerName: "Invoice Status",
    cellDataType: "text",
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["draft", "complete"],
    },
    width: 150,
    sortOrder: "id",
    valueFormatter: workflowStateValueFormatter,
  },
  {
    field: "order_status",
    colId: "order_status.name",
    headerName: "Order Status",
    cellDataType: "text",
    width: 150,
    filter: "agSetColumnFilter",
    filterParams: {
      values: [
        "New",
        "Pending",
        "To Pick",
        "To Arrive",
        "In Transit",
        "Picking",
        "Staged",
        "Packing",
        "Ready To Ship",
        "Shipped",
        "Delivered",
        "Arrived",
        "Receiving",
        "Received",
        "Put Away",
        "At Warehouse",
        "Billed",
        "Scheduled for Pickup",
        "Labeled",
        "On Pallet",
        "On the Truck",
        "Canceled",
        "Hold",
        "Failed Delivery",
        "Parcel Pack",
        "Out for Pickup",
        "Audited",
        "Unloading",
        "Unloaded",
        "Putting-Away",
        "Backordered",
        "Loading Outbound",
      ],
    },
    valueGetter: (params) => params.data?.order_status?.name,
  },
  {
    field: "order_type.name",
    colId: "order_type.name",
    headerName: "Order Type",
    cellDataType: "text",
    width: 120,
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["Inbound", "Outbound", "Special Delivery", "Transport", "Storage"],
    },
  },
]);

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

onBeforeMount(() => {
  detailCellRenderer.value = OrderBillDetailRenderer;
});

const addFuncsToColDefs = function () {
  for (let def of colDefs.value) {
    let colName = def.field;
    if (valueGetters[colName]) {
      def.valueGetter = valueGetters[colName];
    }
  }
};

addFuncsToColDefs();

const gridOptions = {
  rowModelType: "serverSide",
  serverSideDatasource: connectDatasource,
  rowSelection: "multiple",
  cacheBlockSize: 100,
  blockLoadDebounceMillis: 500,
  getRowId: (params) => params.data.id,
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  onSortChanged: (event) => {
    debounce(() => gridApi.refreshServerSideStore(), 300);
  },
  onStateChanged: () => {
    saveGridState(GRID_SAVE_KEY, gridApi);
  },
  onFirstDataRendered: onFirstDataRendered,
};

const validateSameClient = (rows) => {
  if (rows && rows.length > 0) {
    const belongsToSameClient = rows.every(
      ({ client_id }) => client_id === rows[0].client_id
    );
    if (!belongsToSameClient) {
      toast.error("Please select orders for a single client.");
      disableInvoicing.value = true;
      toggleInvoiceRadio.value = null;
      return false;
    }
  }
  return true;
};

const validateAlreadyAddedToInvoice = (rows) => {
  if (rows && rows.length > 0) {
    const invalidRows = rows.filter((row) => {
      return row.order_invoice && row?.order_invoice?.workflow_state !== "";
    });

    if (invalidRows && invalidRows.length > 0) {
      const invalidIds = invalidRows.map((row) => `B-${row.id}`).join(", ");
      if (invalidRows.length > 1) {
        toast.error(`Billing Orders ${invalidIds} already have an invoice.`);
      } else {
        toast.error(`Billing Order ${invalidIds} already has an invoice.`);
      }

      return true;
    }
    return false;
  }
};

const statusFilter = {
  filter: {
    field: "order_invoice.workflow_state",
    filterType: "set",
    name: "invoice-status",
  },
  values: [
    { label: "all", value: ["complete", "draft"] },
    { label: "not invoiced", value: [] },
    { label: "draft", value: ["draft"] },
    { label: "billed", value: ["complete"] },
  ],
};

const notBilledFilter = {
  filter: {
    field: "status_id",
    filterType: "set",
  },
  values: ["For Review", "New", "Pending", "Unknown Status"],
};

const orderTypeFilter = {
  filter: {
    field: "order_type.name",
    filterType: "text",
    type: "equals",
  },
  values: [
    {
      label: "all",
      value: ["inbound", "outbound", "special delivery", "transport", "storage"],
    },
    { label: "inbounds", value: ["inbound"] },
    { label: "outbounds", value: ["outbound"] },
    { label: "slos", value: ["special delivery"] },
    { label: "transportation", value: ["transport"] },
    { label: "storage", value: ["storage"] },
  ],
};

/**
 * Apply filter with a given value to a field.
 * @param {*} filter The filter to be applied.
 * @param {*} value The value that is being filtered.
 */
const applyFilter = async (filter, item) => {
  // Retrieve the current filter model from the grid
  const currentFilterModel = gridApi.getFilterModel();

  // Update the filter model with the new filter criteria
  currentFilterModel[filter.field] = {
    filterType: filter.filterType,
    values: item.value,
  };

  // If not invoiced filter is applied, filter bill status as well so that no billed items are shown.
  if (filter.name === "invoice-status") {
    if (item.label === "not invoiced") {
      currentFilterModel[notBilledFilter.filter.field] = {
        filterType: notBilledFilter.filter.filterType,
        values: notBilledFilter.values,
      };
    } else {
      currentFilterModel[notBilledFilter.filter.field] = {};
    }
  }

  // Set the updated filter model back to the grid
  await gridApi.setFilterModel(currentFilterModel);
};

/**
 * Add the selected billing orders to a new or existing invoice.
 */
const onInvoiceSubmit = async () => {
  let rows = gridApi.getSelectedRows();

  if (!validateSameClient(rows)) {
    return;
  }

  if (validateAlreadyAddedToInvoice(rows)) {
    return;
  }

  try {
    const orderBillIds = rows.map((row) => row.id);

    const invoiceId = selectedInvoice;

    if (selectedInvoice.value && toggleInvoiceRadio.value === APPEND_INVOICE_RADIO) {
      // Get the invoice id.
      const payLoad = {
        action: "add",
        order_bill_ids: orderBillIds,
      };

      const url = `/order_invoices/${invoiceId.value}/modify_order_bills`;
      await makePatchApiCall(url, payLoad);
      router.push(`/invoices/${invoiceId.value}`);
    } else if (toggleInvoiceRadio.value === CREATE_INVOICE_RADIO) {
      // create new invoice
      const payLoad = {
        client_id: rows[0].client_id,
        order_bill_ids: orderBillIds,
      };
      // TODO: Nagivate to order_invoices/:id page
      const response = await makePostApiCall("/order_invoices", payLoad);
      router.push(`/invoices/${response.data.data.id}`);
    }
  } catch (errors) {
    console.error(errors);
    toast.error(errors.response.data.data.join(". "));
  }
};

const onSelectionChanged = () => {
  toggleInvoiceRadio.value = null;
  showAppendInvoice.value = false;
  disableInvoiceSubmit.value = true;

  const rows = gridApi.getSelectedRows();
  constructSelectComponentItems();

  if (rows && rows.length > 0) {
    disableInvoicing.value = false;
  } else {
    disableInvoicing.value = true;
    selectedInvoice.value = "";
  }
};

const resetGridFiltersSettings = () => {
  resetGridFilterState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const resetGridColSettings = () => {
  resetGridColState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const onToggleInvoiceRadio = () => {
  if (!validateSameClient()) {
    return;
  }
  if (toggleInvoiceRadio.value === CREATE_INVOICE_RADIO) {
    showAppendInvoice.value = false;
    disableInvoiceSubmit.value = false;
  } else if (toggleInvoiceRadio.value === APPEND_INVOICE_RADIO) {
    showAppendInvoice.value = true;
    if (!selectedInvoice.value) {
      disableInvoiceSubmit.value = true;
    }
    constructSelectComponentItems();
  }
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const toggleDialog = (toggleVal) => {
  dialog.value = toggleVal;
  return dialog;
};

const rowDoubleClicked = function (event) {
  gridParams = event;
  gridParams.dialog = toggleDialog(true);
  gridParams.toggleDialog = toggleDialog;
};

const processSlos = async function () {
  const params = {
    date:slosProcessDate.value
  };
  await c3api.post("/order_bills/process-slos", params)
};

watch(showAppendInvoice, (showAppendInvoice) => {
  if (!showAppendInvoice) {
    selectedInvoice.value = "";
  }
});

watch(selectedInvoice, (newValue, oldValue) => {
  if (newValue) {
    disableInvoiceSubmit.value = false;
  } else if (!toggleInvoiceRadio) {
    disableInvoiceSubmit.value = true;
  }
});
</script>

<template>
  <div v-if="dialog">
    <BillingOrderActivitiesPopup :params="gridParams" />
  </div>

  <div class="d-flex mb-2">
    <v-card-title>{{ pageTitle }}</v-card-title>
    <v-dialog max-width="800">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            v-bind="activatorProps"
            class = "mr-6 ms-auto"
            text="Process SLOs"
            variant="outlined"
          ></v-btn>
        </template>

        <template v-slot:default="{ isActive }">

          <v-card title="Process SLOs">
            <v-card-text>
              <v-date-picker color="primary" width="400" v-model="slosProcessDate"></v-date-picker>
              Processing takes about 60 seconds. Please refresh the bills after this time.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                text="PROCESS"
                variant="outlined"
                class="ma-2"
                @click="() => {
                  processSlos(event);
                  isActive.value = false;
                }"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>
  </div>

  <v-divider></v-divider>

  <div style="height: 100%">
    <div class="add-to-invoice d-flex mt-4">
      <div>
        <v-radio-group
          class="ml-2 mb-1"
          hide-details
          :disabled="disableInvoicing"
          @change="onToggleInvoiceRadio"
          v-model="toggleInvoiceRadio"
          inline
        >
          <v-radio label="Create New Invoice" value="create_invoice"></v-radio>
          <v-radio
            label="Add to an Existing Invoice"
            value="append_invoice"
            select="handleSwitchToggle"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="select-component">
        <v-select
          v-if="showAppendInvoice"
          label="Select Invoice for Client"
          v-model="selectedInvoice"
          :items="invoicesSelect"
          hide-details
          item-title="text"
          item-value="value"
        >
        </v-select>
      </div>
    </div>

    <v-btn
      variant="tonal"
      :disabled="disableInvoiceSubmit"
      class="mb-4 ml-4 mt-2 submitButton"
      @click="onInvoiceSubmit"
      >Submit</v-btn
    >

    <div class="d-flex">
      <div class="filterToggle">
        <v-btn
          v-if="!showFilters"
          variant="text"
          @click="showFilters = true"
          append-icon="mdi-eye-outline"
          >Filters</v-btn
        >
        <v-btn
          v-if="showFilters"
          variant="text"
          @click="showFilters = false"
          append-icon="mdi-eye-off-outline"
          >Filters</v-btn
        >
      </div>

      <div class="d-flex-end ml-auto mr-2">
        <v-btn variant="text" @click="resetGridFiltersSettings"
          >Reset Filters</v-btn
        >
        <v-btn variant="text" @click="resetGridColSettings">Reset Columns</v-btn>
      </div>
    </div>

    <div id="billings-orders-wrapper" class="d-flex" style="height: 89%">
      <v-card class="mt-4 p-6 mr-5 overflow-visible" :class="{ filter: !showFilters }">
        <v-card-title class="mt-5">Invoice Status</v-card-title>
        <v-btn
          v-for="(item, index) in statusFilter.values"
          :key="index"
          @click="applyFilter(statusFilter.filter, item)"
          block
          hide-details
          variant="text"
          class="justify-start"
          >{{ item.label }}</v-btn
        >

        <v-card-title class="mt-5">Order Type</v-card-title>
        <v-btn
          v-for="(item, index) in orderTypeFilter.values"
          :key="index"
          @click="applyFilter(orderTypeFilter.filter, item)"
          hide-details
          block
          variant="text"
          class="justify-start"
          >{{ item.label }}</v-btn
        >
      </v-card>

      <AgGridVue
        style="width: 100%; height: 100%"
        :class="themeStore.agTheme"
        :columnDefs="colDefs"
        :defaultColDef="defaultColDef"
        :gridOptions="gridOptions"
        :onSelectionChanged="onSelectionChanged"
        @rowDoubleClicked="rowDoubleClicked"
        @grid-ready="onGridReady"
        @state-updated="onStateUpdated"
        :context="context"
      >
      </AgGridVue>
    </div>
  </div>
</template>

<style scoped>
.ag-icon {
  display: flex !important;
}
</style>

<style scoped>
.filterToggle {
  margin-left: 15px;
}

.filter {
  display: none;
}

.select-component {
  width: 15%;
  margin-top: 25px;
  margin-left: 15px;
}

#billings-orders-wrapper {
  width: 100%;
  height: 100%;
  /* Ensure it takes full height */
  padding: 0;
  /* Remove padding to ensure full height utilization */
  box-sizing: border-box;
}

.filter-card {
  flex-basis: 25%;
}

.select-invoice {
  width: 60%;
  margin: 10px;
  padding: 5px;
}

.submitButton {
  background: rgb(67, 147, 67);
  color: aliceblue;
}
</style>
